import * as React from 'react'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Biografija = () => {
  return (
    <>
      <Seo title='Mario Kružić, kandidat za Gradonačelnika' />
      <Layout>
        <div className='my-8 md:grid md:grid-cols-2 md:gap-8 md:place-content-start	'>
          <StaticImage
            src='../images/Mario-Kruzic.jpg'
            alt='Mario Kružić'
            layout='fullWidth'
            placeholder='blurred'
            quality='100'
            objectFit='contain'
            objectPosition='0 0'
            as='div'
            className='mb-4 wide'
          />
          <div className='bio'>
            <h2>MARIO KRUŽIĆ</h2>
            <p>
              <em>život i karijera</em>
            </p>
            <p>&nbsp;</p>
            <p>
              Rođen sam u rujnu 1985. godine, a od rođenja do danas privatno sam
              i profesionalno vezan za svoju rodnu Crikvenicu.
            </p>
            <p>
              Po struci sam ekonomista. U želji za stjecanjem novih kompetencija
              i stručnim usavr&scaron;avanjem, upisao sam poslijediplomski
              specijalistički studij menadžmenta u javnom sektoru, a trenutno
              sam polaznik doktorskog studija Pomorstvo na Pomorskom fakultetu
              Sveučili&scaron;ta u Rijeci.
            </p>
            <p>
              Poslovna karijera vezana mi je za&nbsp; Županijsku lučku upravu
              gdje započinjem s radom 2011. godine kao pripravnik. Ubrzo nakon
              zavr&scaron;etka pripravničkog staža postajem voditelj odjela za
              opće i financijske poslove da bi 2014. godine postao ravnatelj. U
              Županijskoj lučkoj upravi kroz godine vodim brojne investicije u
              luke i lučka područja kojim upravljamo, a u tijeku je najveći
              izazov - EU sredstvima sufinanciran projekt Dogradnje luke
              Crikvenica vrijedan 33 mil. kuna.
            </p>
            <p>
              U politički život Crikvenice značajnije se uključujem 2017. godine
              kao vijećnik u Gradskom vijeću te član Odbora za za&scaron;titu
              okoli&scaron;a i prostorno planiranje Grada Crikvenice, te Odbora
              za izbor i imenovanje Grada Crikvenice.
            </p>
            <p>
              Član sam Gradskog odbora SDP-a od 2013. godine, predsjednik
              gradske organizacije od 2017. g. i član predsjedni&scaron;tva
              Županijske organizacije SDP-a od 2018. godine.
            </p>
            <p>
              Malo slobodnog vremena koje imam posvećujem supruzi i aktivnostima
              vezanim uz rad klape Tić iz Triblja čiji sam predsjednik.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Biografija
